import { Box } from '@mui/material'
import React from 'react'
import SellerResportContainer from '../../containers/SellerResportContainer'

export default function SellerReport() {
  return (
    <Box>
      <SellerResportContainer />
    </Box>
  )
}
