import { Box } from '@mui/material'
import React from 'react'
import SellerSuppliersContainer from '../../containers/SellerSuppliersContainer'

export default function SellerSuppliers() {
  return (
    <Box>
      <SellerSuppliersContainer />
    </Box>
  )
}
