import { Box } from '@mui/material'
import React from 'react'
import SellersContainer from '../../containers/SellersContainer'

export default function Sellers() {
  return (
    <Box>
      <SellersContainer />
    </Box>
  )
}
