import { Box } from '@mui/material'
import React from 'react'
import ComissionRatesContainer from '../../containers/ComissionRatesContainer'

export default function ComissionRates() {
  return (
    <Box>
      <ComissionRatesContainer />
    </Box>
  )
}
