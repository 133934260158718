import { Box } from '@mui/material'
import React from 'react'
import SellerBranchesContainer from '../../containers/SellerBranchesContainer'

export default function SellerBranches() {
  return (
    <Box>
      <SellerBranchesContainer />
    </Box>
  )
}
