import { Box } from '@mui/material'
import React from 'react'
import BanksContainer from '../../containers/BanksContainer'

export default function Banks() {
  return (
    <Box>
      <BanksContainer />
    </Box>
  )
}
