import { Box } from '@mui/material'
import React from 'react'
import SuppliersContainer from '../../containers/SuppliersContainer'

export default function Suppliers() {
  return (
    <Box>
      <SuppliersContainer />
    </Box>
  )
}
