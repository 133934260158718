import { Box } from '@mui/material'
import React from 'react'
import SellerProductsContainer from '../../containers/SellerProductsContainer'

export default function SellerProducts() {
  return (
    <Box>
      <SellerProductsContainer />
    </Box>
  )
}
