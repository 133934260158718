import { Box } from '@mui/material'
import React from 'react'
import LastUsersContainer from '../../containers/LastUsersContainer'

export default function LastUsers() {
  return (
    <Box>
      <LastUsersContainer />
    </Box>
  )
}
