import { Box } from '@mui/material'
import React from 'react'
import GetPaymentContainer from '../../containers/GetPaymentContainer'

export default function GetPayment() {
  return (
    <Box>
      <GetPaymentContainer />
    </Box>
  )
}
