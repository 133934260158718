import { Box } from '@mui/material'
import React from 'react'
import UsersContainer from '../../containers/UsersContainer'

export default function Users() {
  return (
    <Box>
      <UsersContainer />
    </Box>
  )
}
