import { Box } from '@mui/material'
import React from 'react'
import SupplierAddEditContainer from '../../containers/SupplierAddEditContainer'

export default function SupplierAddEdit() {
  return (
    <Box>
      <SupplierAddEditContainer />
    </Box>
  )
}
