import { Box } from '@mui/material'
import React from 'react'
import AdminReportContainer from '../../containers/AdminReportContainer'

export default function AdminReport() {
  return (
    <Box>
      <AdminReportContainer />
    </Box>
  )
}
